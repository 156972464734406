import axios from 'axios'

const API = 'landing/seccion'

const Productos = {
    postLandingSeccion(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    detalleLandingSeccion(params){
        return axios.get(`${API}/detalle`,{params})
    },
    actualizarSeccion(payload){
        return axios.put(`${API}/actualizar-seccion`,payload)
    },
    selectCategorias(params){
        return axios.get(`${API}/select-categorias`,{params})
    },
    selectProductos(params){
        return axios.get(`${API}/productos`,{params})
    },
    postProducto(payload){
        return axios.post(`${API}/guardar/producto`,payload)
    },
    getProductos(params){
        return axios.get(`${API}/elementos/productos`,{params})
    },
    deleteProductos(idElemento){
        return axios.delete(`${API}/eliminar/producto/${idElemento}`)
    },
    cambiarPosicion(payload){
        return axios.post(`${API}/cambiar-posicion`,payload)
    },

}

export default Productos
