<template>
    <modal ref="modalCrearProductos" titulo="Productos" no-aceptar tamano="modal-xl">
        <div class="d-middle mx-0 bg-fondo w-100 position-absolute header-modal">
            <div class="col d-middle pl-5 border-right h-100">
                <span class="f-12 text-general">Categoría</span>
                <div class="col-5">
                    <el-select v-model="idCategoria" size="small" class="w-100">
                        <el-option
                        v-for="item in categorias"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-5">
                    <el-select 
                    v-model="idProducto" 
                    class="w-100" 
                    size="small" 
                    filterable 
                    remote
                    reserve-keyword
                    placeholder="Buscar producto" 
                    :remote-method="busquedaSelect"
                    @change="guardarProducto"
                    >
                        <el-option
                        v-for="item in productos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-4 d-middle h-100">
                <span class="f-16 text-general">Parámetros</span>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0">
            <draggable tag="div" :list="listProductos" class="col pt-4 overflow-auto custom-scroll" style="max-height: calc(100vh - 460px);" handle=".handle" @change="onDrag">
                <div v-for="(p,key) in listProductos" :key="key" class="row mx-0 pl-5 align-items-center mb-3">
                    <i v-if="seccion.parametro == 53" class="icon-drag handle f-20 text-general" />
                    <div class="col-6 px-2 text-general f-16">
                        {{ p.nombre }} 
                        <small class="text-grey">
                            {{ p.presentacion }}
                        </small>
                    </div>
                    <i class="icon-delete-trash f-20 text-general cr-pointer" @click="deleteProductos(p)" />
                </div>
            </draggable>
            <div class="border-left px-3 pt-4 overflow-auto custom-scroll" style="width:362px; max-height: calc(100vh - 460px);">
                <p class="text-general f-16 mb-3">
                    Líneas del listado
                </p>
                <div class="row mx-0 mb-3">
                    <button type="button" class="box-lineas mr-3" :class="seccion.tipo == 41 ? 'bg-gris' : '' " @click="actualizarSeccion(41,'tipo')">
                        1
                    </button>
                    <button type="button" class="box-lineas" :class="seccion.tipo == 42 ? 'bg-gris' : '' " @click="actualizarSeccion(42,'tipo')">
                        2
                    </button>
                </div>
                <p class="text-general f-16 mb-3">
                    Ordenar por
                </p>
                <el-radio-group v-model="seccion.parametro">
                    <div class="row mx-0 mb-3">
                        <el-radio :label="41" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Alfabético descedente
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="42" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Alfabético ascendente
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="43" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Más clicks
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="44" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menos clicks
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="45" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Mayor precio
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="46" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menor precio
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="47" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Mas vendido (Últ. 60 días)
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="48" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menos vendido (Últ. 60 días)
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="49" disabled class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Mayor descuento
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="50" disabled class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menor descuento
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="53" class="radio-green" @change="actualizarSeccion($event,'parametro')"> 
                            Personalizado
                        </el-radio>
                    </div>
                </el-radio-group>
            </div>
        </div>
    </modal>
</template>

<script>
import LandingProductos from '~/services/landing/landingProductos'
export default {
    data(){
        return {
            categorias: [],
            idCategoria: 0,
            buscar: '',
            idLandingSeccion: null,
            seccion: {},
            productos: [],
            idProducto: null,
            listProductos: []
        }
    },
    computed: {
        idLanding(){
            return this.$route.params.idLanding
        }
    },
    watch: {
        idCategoria(){
            this.selectProductos()
        }
    },
    mounted(){
    },
    methods: {
        toggle(idSeccion = null){
            if (idSeccion){
                this.idLandingSeccion = idSeccion
                this.detalleLandingSeccion();
            }else{
                this.postLandingSeccion();  
            }
            this.buscar = ''
            this.idProducto = null
            this.idCategoria = 0
            this.$refs.modalCrearProductos.toggle();
        },
        async postLandingSeccion(){
            try {
                const { data } = await LandingProductos.postLandingSeccion({idLanding: this.idLanding});
                this.idLandingSeccion = data?.data.id
                this.detalleLandingSeccion();
            } catch (e){
                this.error_catch(e)
            } 
        },
        async detalleLandingSeccion(){
            try {
                const { data } = await LandingProductos.detalleLandingSeccion({idSeccion: this.idLandingSeccion});
                this.seccion = data?.data
                this.selectCategorias();
                this.getProductos()
            } catch (e){
                this.error_catch(e)
            } 
        },
        async actualizarSeccion(value,campo){
            try {
                if (campo == 'tipo' && this.seccion.tipo != value || campo == 'parametro'){
                    const { data } = await LandingProductos.actualizarSeccion({campo: campo, valor: value, idSeccion: this.idLandingSeccion});
                    this.seccion.tipo = data.data.tipo
                    this.seccion.parametro = data.data.parametro
                    if (campo == 'parametro'){
                        this.getProductos();                        
                    }
                    if(campo == 'tipo'){
                        this.$emit('updateParametro');
                    }
                }
            } catch (e){
                this.error_catch(e)
            } 
        },
        async selectCategorias(){
            try {
                const { data } = await LandingProductos.selectCategorias();
                this.categorias = data?.data
                this.selectProductos();
            } catch (e){
                this.error_catch(e)
            } 
        },
        busquedaSelect(buscar){
            const change = () => {
                if(buscar.length > 1){
                    this.buscar = buscar
                    this.selectProductos()
                }else{
                    this.buscar = ''
                    this.selectProductos()
                }
            }
            this.delay(change)
        },
        async selectProductos(){
            try {
                const params = {
                    idSeccion: this.idLandingSeccion,
                    buscar: this.buscar,
                    idCategoria: this.idCategoria
                }

                const { data } = await LandingProductos.selectProductos(params);
                this.productos = data?.data
            } catch (e){
                this.error_catch(e)
            } 
        },
        async guardarProducto(id){
            try {
                await LandingProductos.postProducto({idSeccion: this.idLandingSeccion, idProducto: id});
                this.buscar = ''
                this.idProducto = null
                this.productos = this.productos.filter(e => e.id != id)
                this.getProductos()
                
                setTimeout(() => {
                    if(this.listProductos.length < 2){
                        this.$store.commit('landing/landing/setListenerUpdater', { value:'producto', type: 'nuevaData', idSection: null });
                    } else {
                        this.$store.commit('landing/landing/setListenerUpdater', { value:'producto', type: 'actualizarData', idSection: this.idLandingSeccion });
                    }
                },1000);
            } catch (e){
                this.error_catch(e)
            } 
        },
        async getProductos(){
            try {
                const params = {
                    idSeccion: this.idLandingSeccion,
                    ordenar: this.seccion.parametro,
                    idLanding: this.idLanding
                }

                const { data } = await LandingProductos.getProductos(params);
                this.listProductos = data?.data
            } catch (e){
                this.error_catch(e)
            } 
        },
        async deleteProductos(data){
            try {
                await LandingProductos.deleteProductos(data.id);
                this.listProductos = this.listProductos.filter(e => e.id != data.id)

                this.$store.commit('landing/landing/setListenerUpdater', { value:'producto', type: 'actualizarData', idSection: this.idLandingSeccion });
            } catch (e){
                this.error_catch(e)
            } 
        },
        async onDrag(datos){
            try {
                console.log('entor aca papa', datos);
                let payload = {
                    nuevaPosicion: datos.moved.newIndex + 1,
                    antiguaPosicion: datos.moved.oldIndex + 1,
                    idElemento: datos.moved.element.id,
                    idSeccion: this.idLandingSeccion
                }

                await LandingProductos.cambiarPosicion(payload)
                this.getProductos();

            } catch (error){
                this.error_catch(error)
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.header-modal{
    height: 53px;
    left: 0px;
}

.box-lineas{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    background-color: #FFFFFF;
    border-radius: 8px;
    &:focus{
        background-color: #F6F9FB;
    }
}

</style>